<template>
  <div class="modal fade" ref="transformModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-flask me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row" ref="inputNotes">
              <div class="col">
                <div class="mb-3">
                  <label for="container-notes">Σημειώσεις</label>
                  <textarea @keydown.enter.prevent="handleEnterKey" class="form-control" id="container-notes"
                            v-model="form.notes"></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";
import menuTitles from "@/assets/js/Library/MenuTitles";

export default {
  name: 'TransformModal',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {VueSelect},
  created() {
    window.transform = this.transform;
  },
  data() {
    return {
      title: '',
      button: null,
      privileges: {
        canTransform: (BigInt(localStorage.getItem('privilege')) & Privileges.actions.CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS.value) === Privileges.actions.CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS.value
      },
      modalRef: null,
      privilege: null,
      form: {
        id: 0,
        notes: ''
      }
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.transformModal);
  },
  methods: {
    prepareTransform(id) {
      if (!this.privileges.canTransform) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();

      this.title = 'Επεξεργασία';
      this.button = ModalButtons.transform(id)

      this.clearForm();

      this.openModal();
    },
    transform(id) {
      if (!this.privileges.canTransform) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      const data = new FormData();
      data.append('container_id', id);
      data.append('notes', this.form.notes);

      axios.post(apiRoutes.actions.transform, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          })
          this.closeModal();
          this.$emit('data-modified');
        }
      }).catch((error) => ErrorHandle(error));
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.form.id = 0;
      this.form.notes = '';
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    }
  }
}
</script>

<style scoped>

</style>