<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col float-start"><i class="fas fa-table me-1"></i>Πίνακας κινήσεων</div>
        <div class="col" v-if="this.privileges.canSeeTransfersInfo">
          <button class="btn btn-outline-dark float-end" @click="prepareGenerateReport"><i
              class="fas fa-file-arrow-down me-1"></i>Εξαγωγή
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col float-start">
          <div class="d-none d-md-flex">
            Κινήσεις:
            <a href="javascript:void(0)"><span class="m-1" @click="filterByType('')"><i class="badge rounded-pill bg-black">Όλες</i></span></a>
            <a href="javascript:void(0)"><span class="m-1" @click="filterByType('Γέμισμα')"><i class="badge rounded-pill bg-primary">Γέμισμα</i></span></a>
            <a href="javascript:void(0)"><span class="m-1" @click="filterByType('Εμφιάλωση')"><i class="badge rounded-pill bg-success">Εμφιάλωση</i></span></a>
            <a href="javascript:void(0)"><span class="m-1" @click="filterByType('Μεταφορά')"><i class="badge rounded-pill bg-info">Μεταφορά</i></span></a>
            <a href="javascript:void(0)"><span class="m-1" @click="filterByType('Επεξεργασία')"><i class="badge rounded-pill bg-secondary">Επεξεργασία</i></span></a>
          </div>
          <div class="d-flex d-md-none">
            <a class="nav-link dropdown-toggle" id="typeOfMoveDropdown" href="#" role="button"
               data-bs-toggle="dropdown" aria-expanded="false">Κινήσεις</a>
            <ul class="dropdown-menu" aria-labelledby="typeOfMoveDropdown">
              <li><a href="javascript:void(0)"><span class="m-1" @click="filterByType('')"><i class="badge rounded-pill bg-black">Όλες</i></span></a></li>
              <li><a href="javascript:void(0)"><span class="m-1" @click="filterByType('Γέμισμα')"><i class="badge rounded-pill bg-primary">Γέμισμα</i></span></a></li>
              <li><a href="javascript:void(0)"><span class="m-1" @click="filterByType('Εμφιάλωση')"><i class="badge rounded-pill bg-success">Εμφιάλωση</i></span></a></li>
              <li><a href="javascript:void(0)"><span class="m-1" @click="filterByType('Μεταφορά')"><i class="badge rounded-pill bg-info">Μεταφορά</i></span></a></li>
              <li><a href="javascript:void(0)"><span class="m-1" @click="filterByType('Επεξεργασία')"><i class="badge rounded-pill bg-secondary">Επεξεργασία</i></span></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-xxl-3 col-xl-4 col-md-4">
          <select class="form-control form-select" v-model="table.perPage" @change="setPerPage($event.target.value)">
            <option value="10">10 Εγγραφές / Σελίδα</option>
            <option value="25">25 Εγγραφές / Σελίδα</option>
            <option value="50">50 Εγγραφές / Σελίδα</option>
            <option value="100">100 Εγγραφές / Σελίδα</option>
          </select>
        </div>
        <div class="col-xxl-7 col-xl-4 col-md-4"></div>
        <div class="col-xxl-2 col-xl-4 col-md-4">
          <input type="text" class="form-control" placeholder="Αναζήτηση..." v-model="table.search" @input="fetchData">
        </div>
      </div>

      <table class="table table-hover">
        <thead>
        <tr>
          <TableColumn class="d-none d-xl-table-cell" :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="container1_id" name="Προέλευση" icon="fa-whiskey-glass" @sort="sort" />
          <TableColumn class="d-none d-xl-table-cell" :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="container2_id" name="Προορισμός" icon="fa-whiskey-glass" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="transfer_type_id" name="Κίνηση" icon="fa-right-left" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="quantity" name="Ποσότητα" icon="fa-filter" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="created_at" name="Ημερομηνία" icon="fa-calendar" @sort="sort" />
          <th class="text-center" scope="col"><i class="fas fa-cogs me-2"></i><span class="d-none d-xl-inline">Ενέργειες</span></th>
        </tr>
        </thead>
        <tfoot>
        <tr>
          <th class="d-none d-xl-table-cell"><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.container1Search" @input="fetchData"></th>
          <th class="d-none d-xl-table-cell"><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.container2Search" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.typeSearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.quantitySearch" @input="fetchData"></th>
          <th><VueDatePicker
              ref="datepicker"
              v-model="table.dateSearch"
              locale="el"
              cancelText="Ακύρωση"
              selectText="Αναζήτηση"
              placeholder="Ημερομηνία"
              range
              :enable-time-picker="false"
              @update:model-value="fetchData"
          ></VueDatePicker></th>
          <th class="text-center"><i class="fas fa-gear me-1"></i></th>
        </tr>
        </tfoot>
        <tbody>
        <tr v-if="transfers.length === 0">
          <td colspan="6" class="text-center">Δεν βρέθηκαν εγγραφές!</td>
        </tr>
        <tr v-for="transfer in transfers" :key="transfer.id">
          <td class="d-none d-xl-table-cell text-center" :colspan="transfer.container1_id === transfer.container2_id ? 2 : 1">{{ transfer.container1_name }} <span v-if="transfer.product_id > 0">({{ transfer.content_1 }})</span> </td>
          <td class="d-none d-xl-table-cell text-center" v-if="transfer.container1_id !== transfer.container2_id">{{ transfer.container2_name }}</td>
          <td class="text-center">
            <span v-if="parseInt(transfer.transfer_type_id) === 1" class="badge rounded-pill bg-primary">{{ transfer.transfer_type_name }}</span>
            <span v-else-if="parseInt(transfer.transfer_type_id) === 2" class="badge rounded-pill bg-success">{{ transfer.transfer_type_name }}</span>
            <span v-else-if="parseInt(transfer.transfer_type_id) === 3" class="badge rounded-pill bg-info">{{ transfer.transfer_type_name }}</span>
            <span v-else-if="parseInt(transfer.transfer_type_id) === 4" class="badge rounded-pill bg-secondary">{{ transfer.transfer_type_name }}</span>
          </td>
          <td class="text-center">{{ transfer.quantity }}</td>
          <td class="text-center">
            <i class="fas fa-calendar me-1"></i>{{ transfer.created_date }}
            <i class="fas fa-clock me-1"></i>{{ transfer.created_time }}
          </td>
          <td class="text-center"><span v-html="getActionButtons(transfer.id)"></span></td>
        </tr>
        </tbody>
      </table>

      <Footer :search-value="table.search" :paging-object="paging" :data-length="transfers.length" @page-changed="changePage"/>
    </div>
  </div>
  <TransfersModal ref="transfersModal" @data-modified="fetchData"/>
  <ReportModal ref="reportModal"/>

</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";

import axios from "axios";
import ApiRoutes from "@/router/ApiRoutes";
import Privileges from "@/assets/js/Library/Privileges";
import PaginatorComponent from "@/components/TableComponents/Paginator.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import TransfersModal from "@/views/Transfers/TransfersModal.vue";
import ReportModal from "@/views/Transfers/ReportModal.vue";
import Modals from "@/assets/js/Library/Modals";
import TableColumn from "@/components/TableComponents/TableColumn.vue";
import Footer from "@/components/TableComponents/Footer.vue";
import ErrorHandle from "@/assets/js/Library/ErrorHandle";

export default {
  name: 'TransfersTable',
  components: {Footer, TableColumn, ReportModal, TransfersModal, VueDatePicker, PaginatorComponent, AdminLayout},
  created() {
    window.store = this.store;
    window.edit = this.edit;
    window.update = this.update;
    window.destroy = this.destroy;
  },
  data() {
    return {
      privileges: {
        canSeeTransfersData: (BigInt(localStorage.getItem('privilege')) & Privileges.transfers.CAN_SEE_TRANSFERS_DATATABLE.value) === Privileges.transfers.CAN_SEE_TRANSFERS_DATATABLE.value,
        canSeeTransfersInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.transfers.CAN_SEE_TRANSFERS_INFO.value) === Privileges.transfers.CAN_SEE_TRANSFERS_INFO.value,
        canSeeLogsInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.logs.CAN_SEE_LOGS_INFO.value) === Privileges.logs.CAN_SEE_LOGS_INFO.value,
        canFilterContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_FILTER_CONTAINERS.value) === Privileges.containers.CAN_FILTER_CONTAINERS.value,
      },
      reportModalRef: null,
      transfers: [],
      paging: {
        from: 1,
        to: 10,

        current_page: 1,
        last_page: 1,

        per_page: 10,
        total: 0,

        totalRecords: 0
      },
      table: {
        search: '',
        sort_by: 'created_at',
        sort_dir: 'desc',
        perPage: 10,
        page: 1,
        container1Search: '',
        container2Search: '',
        typeSearch: '',
        quantitySearch: '',
        dateSearch: '',
      }
    }
  },
  mounted() {
    this.fetchData();
    this.reportModalRef = this.$refs.reportModal;
  },
  methods: {
    fetchData() {
      if (!this.privileges.canSeeTransfersData) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      let data = new FormData();
      data.append('search', this.table.search);
      data.append('sort_by', this.table.sort_by);
      data.append('sort_dir', this.table.sort_dir);
      data.append('perPage', this.table.perPage);
      data.append('page', this.table.page);

      data.append('container1Search', this.table.container1Search);
      data.append('container2Search', this.table.container2Search);
      data.append('typeSearch', this.table.typeSearch);
      data.append('quantitySearch', this.table.quantitySearch);
      if (this.table.dateSearch) {
        const formattedDate = [this.table.dateSearch[0].toLocaleDateString(), this.table.dateSearch[1].toLocaleDateString()]
        data.append('dateSearch', formattedDate);
      }

      axios.post(ApiRoutes.transfers.get, data, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }).then((response) => {
        this.transfers = response.data.results.data;
        this.paging.from = response.data.results.from;
        this.paging.to = response.data.results.to;
        this.paging.current_page = response.data.results.current_page;
        this.paging.last_page = response.data.results.last_page;
        this.paging.per_page = response.data.results.per_page;
        this.paging.total = response.data.results.total;
        this.paging.totalRecords = response.data.totalRecords;
      });
    },
    getActionButtons(id) {
      return `<button class="btn btn-sm btn-outline-dark" onclick="info(${id})"><i class="fas fa-info-circle"></i></button>`
    },
    setPerPage(value) {
      this.table.perPage = value;
      this.fetchData(); // Call method to fetch data with new perPage value
    },
    changePage(page) {
      this.table.page = page;
      this.fetchData();
    },
    sort(field, dir) {
      this.table.sort_by = field;
      this.table.sort_dir = dir;
      this.fetchData();
    },
    filterByType(type) {
      this.table.typeSearch = type;
      this.fetchData();
    },
    prepareGenerateReport() {
      this.reportModalRef.filterContainers('');
      this.reportModalRef.openModal();
    },
  }
}
</script>

<style>
th {
  cursor: pointer;
}
</style>
