const modals = {
    create: '<button id="modal-confirm-button" class="btn btn-primary" onclick="store()"><i class="fas fa-save me-1"></i> Αποθήκευση</button>',
    update: (id) => `<button id="modal-confirm-button" class="btn btn-primary" onclick="update(${id})"><i class="fas fa-rotate me-1"></i> Ενημέρωση</button>`,
    add: (id) => `<button id="modal-confirm-button" class="btn btn-primary" onclick="add(${id})"><i class="fas fa-droplet me-1"></i> Γέμισμα</button>`,
    bottle: (id) => `<button id="modal-confirm-button" class="btn btn-primary" onclick="bottle(${id})"><i class="fas fa-bottle-droplet me-1"></i> Εμφιάλωση</button>`,
    blend: (id, product_id, level) => `<button id="modal-confirm-button" class="btn btn-primary" onclick="blend(${id}, ${product_id}, ${level})"><i class="fas fa-shuffle me-1"></i> Σύμμιξη</button>`,
    transform: (id) => `<button id="modal-confirm-button" class="btn btn-primary" onclick="transform(${id})"><i class="fas fa-flask me-1"></i> Επεξεργασία</button>`,
    report: `<button id="modal-confirm-button" class="btn btn-primary" onclick="generateReport()"><i class="fas fa-file-arrow-down me-1"></i> Εξαγωγή</button>`,
    privileges: (id) => `<button id="modal-confirm-button" class="btn btn-primary" onclick="updatePrivileges(${id})"><i class="fas fa-shield me-1"></i> Αναδιανομή</button>`,
}
export default modals;