<template>

  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col"><i class="fas fa-table me-1"></i>Πίνακας Διαχείρισης Δεξαμενών</div>
        <div class="col" v-if="privileges.canStoreContainers">
          <button @click="prepareStore" class="btn btn-outline-dark float-end"><i class="fas fa-plus me-2"></i>Προσθήκη Νέας</button>
          <button @click="exportContainers" class="btn btn-outline-dark float-end me-1"><i class="fas fa-file-arrow-down me-2"></i>Εξαγωγή</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-xxl-3 col-xl-4 col-md-4">
          <select class="form-control form-select" v-model="table.perPage" @change="setPerPage($event.target.value)">
            <option value="10">10 Εγγραφές / Σελίδα</option>
            <option value="25">25 Εγγραφές / Σελίδα</option>
            <option value="50">50 Εγγραφές / Σελίδα</option>
            <option value="100">100 Εγγραφές / Σελίδα</option>
          </select>
        </div>
        <div class="col-xxl-7 col-xl-4 col-md-4"></div>
        <div class="col-xxl-2 col-xl-4 col-md-4">
          <input type="text" class="form-control" placeholder="Αναζήτηση..." v-model="table.search" @input="fetchData">
        </div>
      </div>

      <table class="table table-hover d-none d-lg-table">
        <thead>
        <tr>
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" title="Κωδικός" field="name" name="Κωδικός" icon="fa-hashtag" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" title="Προϊόν" field="product_name" name="Προϊόν" icon="fa-wine-bottle" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" title="Αλκοολικοί Βαθμοί" field="alcohol" name="Αλκοολ. ΒΘΜ" icon="fa-percentage" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" title="Λίτρα Ένυδρα" field="level" name="Λίτρα Ένυδρα" icon="fa-filter" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" title="Λίτρα Άνυδρα" field="pure" name="Λίτρα Άνυδρα" icon="fa-filter" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" title="Συνολική Χωρητικότητα" field="capacity" name="Συν. Χωρητικότητα" icon="fa-filter" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" title="Πληρότητα" field="overview" name="Πληρότητα" icon="fa-fill" @sort="sort" />
          <th class="text-center" scope="col" title="Ενέργειες"><i class="fas fa-cogs me-2"></i></th>
        </tr>
        </thead>
        <tfoot>
        <tr>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.nameSearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.productSearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.alcoholSearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.levelSearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.pureSearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.capacitySearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.overviewSearch" @input="fetchData"></th>
          <th class="text-center"><i class="fas fa-gear me-1"></i></th>
        </tr>
        </tfoot>
        <tbody>
        <tr v-if="containers.length === 0">
          <td colspan="8" class="text-center">Δεν βρέθηκαν εγγραφές!</td>
        </tr>
        <tr v-for="container in containers" :key="container.id">
          <td class="text-center">{{ container.name }}</td>
          <td class="text-center">{{ container.product_name }}</td>
          <td class="text-center">{{ container.alcohol }}</td>
          <td class="text-center">{{ container.level }}</td>
          <td class="text-center">{{ container.pure }}</td>
          <td class="text-center">{{ container.capacity }}</td>
          <td class="text-center">
            <div class="progress" :title="`${container.overview}%`">
              <div v-if="container.overview >= 0 && container.overview < 25" class="progress-bar bg-success" role="progressbar" :style="`width: ${container.overview}%;`" :aria-valuenow="container.overview" aria-valuemin="0" aria-valuemax="100">
                {{ container.overview }}%
              </div>
              <div v-else-if="container.overview >= 25 && container.overview < 50" class="progress-bar bg-info" role="progressbar" :style="`width: ${container.overview}%;`" :aria-valuenow="container.overview" aria-valuemin="0" aria-valuemax="100">
                {{ container.overview }}%
              </div>
              <div v-else-if="container.overview >= 50 && container.overview < 75" class="progress-bar bg-warning" role="progressbar" :style="`width: ${container.overview}%;`" :aria-valuenow="container.overview" aria-valuemin="0" aria-valuemax="100">
                {{ container.overview }}%
              </div>
              <div v-else-if="container.overview >= 75 && container.overview <= 100" class="progress-bar bg-danger" role="progressbar" :style="`width: ${container.overview}%;`" :aria-valuenow="container.overview" aria-valuemin="0" aria-valuemax="100">
                {{ container.overview }}%
              </div>
            </div>
          </td>
          <td class="text-center"><span v-html="getActionButtons(container.id, container)"></span></td>
        </tr>
        </tbody>
      </table>

      <div class="d-lg-none row justify-content-center">
        <div class="col-6 col-sm-6 col-md-3" v-for="container in containers" :key="container.id" @click="clickMobileContainer(container.id, container)">
          <div class="card custom-card text-center mx-2 my-2 position-relative">
            <div class="card-body">
              <h5 class="card-title">{{ container.name }}</h5>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="fw-bold">Χωρητικότητα</div>
                {{ container.capacity }} Λίτρα
              </li>
              <li class="list-group-item">
                <div class="fw-bold">Προϊόν</div>
                {{ container.product_name }}
              </li>
              <li class="list-group-item">
                <div class="fw-bold">Επίπεδο</div>
                <div class="progress" :title="`${container.overview}%`">
                  <div v-if="container.overview >= 0 && container.overview < 25" class="progress-bar bg-success" role="progressbar" :style="`width: ${container.overview}%;`" :aria-valuenow="container.overview" aria-valuemin="0" aria-valuemax="100">
                    {{ container.overview }}%
                  </div>
                  <div v-else-if="container.overview >= 25 && container.overview < 50" class="progress-bar bg-info" role="progressbar" :style="`width: ${container.overview}%;`" :aria-valuenow="container.overview" aria-valuemin="0" aria-valuemax="100">
                    {{ container.overview }}%
                  </div>
                  <div v-else-if="container.overview >= 50 && container.overview < 75" class="progress-bar bg-warning" role="progressbar" :style="`width: ${container.overview}%;`" :aria-valuenow="container.overview" aria-valuemin="0" aria-valuemax="100">
                    {{ container.overview }}%
                  </div>
                  <div v-else-if="container.overview >= 75 && container.overview <= 100" class="progress-bar bg-danger" role="progressbar" :style="`width: ${container.overview}%;`" :aria-valuenow="container.overview" aria-valuemin="0" aria-valuemax="100">
                    {{ container.overview }}%
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer :search-value="table.search" :paging-object="paging" :data-length="containers.length" @page-changed="changePage"/>
    </div>
  </div>

  <ContainersModal ref="containersModal" @data-modified="fetchData"/>

  <BottlingModal ref="bottlingModal" @data-modified="fetchData"/>

  <AddingModal ref="addingModal" @data-modified="fetchData"/>

  <TransferringModal ref="transferringModal" @data-modified="fetchData"/>

  <BlendingModal ref="blendingModal" @data-modified="fetchData"/>

  <TransformModal ref="transformModal" @data-modified="fetchData"/>

</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";

import axios from "axios";
import ApiRoutes from "@/router/ApiRoutes";
import Privileges from "@/assets/js/Library/Privileges";
import PaginatorComponent from "@/components/TableComponents/Paginator.vue";
import LiquidsModal from "@/views/Liquids/LiquidsModal.vue";
import TableColumn from "@/components/TableComponents/TableColumn.vue";
import Footer from "@/components/TableComponents/Footer.vue";
import ContainersModal from "@/views/Containers/ContainersModal.vue";
import BottlingModal from "@/views/Containers/Actions/BottlingModal.vue";
import AddingModal from "@/views/Containers/Actions/AddingModal.vue";
import TransferringModal from "@/views/Containers/Actions/TransferringModal.vue";
import BlendingModal from "@/views/Containers/Actions/BlendingModal.vue";
import Swal from "sweetalert2";
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import apiRoutes from "@/router/ApiRoutes";
import errorHandle from "@/assets/js/Library/ErrorHandle";
import TransformModal from "@/views/Containers/Actions/TransformModal.vue";

export default {
  name: 'ContainersTable',
  components: {
    TransformModal,
    BlendingModal,
    TransferringModal,
    AddingModal,
    BottlingModal, ContainersModal, Footer, TableColumn, LiquidsModal, PaginatorComponent, AdminLayout},
  created() {
    window.store = this.store;
    window.prepareBottle = this.prepareBottle;
    window.prepareAdd = this.prepareAdd;
    window.prepareTransfer = this.prepareTransfer;
    window.prepareBlend = this.prepareBlend;
    window.prepareTransform = this.prepareTransform;
  },
  data() {
    return {
      privileges: {
        canSeeContainersData: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_SEE_CONTAINERS_DATATABLE.value) === Privileges.containers.CAN_SEE_CONTAINERS_DATATABLE.value,
        canStoreContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_STORE_CONTAINERS.value) === Privileges.containers.CAN_STORE_CONTAINERS.value,
        canEditContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_EDIT_CONTAINERS.value) === Privileges.containers.CAN_EDIT_CONTAINERS.value,
        canUpdateContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_UPDATE_CONTAINERS.value) === Privileges.containers.CAN_UPDATE_CONTAINERS.value,
        canDestroyContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_DESTROY_CONTAINERS.value) === Privileges.containers.CAN_DESTROY_CONTAINERS.value,

        canFilterProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_FILTER_PRODUCTS.value) === Privileges.products.CAN_FILTER_PRODUCTS.value,
        canFilterContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_FILTER_CONTAINERS.value) === Privileges.containers.CAN_FILTER_CONTAINERS.value,
        canAddProductToContainer: (BigInt(localStorage.getItem('privilege')) & Privileges.actions.CAN_ADD_PRODUCT_TO_CONTAINER.value) === Privileges.actions.CAN_ADD_PRODUCT_TO_CONTAINER.value,
        canTransferProductBetweenContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.actions.CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS.value) === Privileges.actions.CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS.value,
        canBottleProduct: (BigInt(localStorage.getItem('privilege')) & Privileges.actions.CAN_BOTTLE_PRODUCT.value) === Privileges.actions.CAN_BOTTLE_PRODUCT.value,
        canBlendProduct: (BigInt(localStorage.getItem('privilege')) & Privileges.actions.CAN_BLEND_PRODUCT.value) === Privileges.actions.CAN_BLEND_PRODUCT.value,
      },
      containers: [],
      paging: {
        from: 1,
        to: 10,

        current_page: 1,
        last_page: 1,

        per_page: 10,
        total: 0,

        totalRecords: 0
      },
      table: {
        search: '',
        sort_by: 'name',
        sort_dir: 'asc',
        perPage: 10,
        page: 1,

        nameSearch: '',
        productSearch: '',
        alcoholSearch: '',
        levelSearch: '',
        pureSearch: '',
        capacitySearch: '',
        overviewSearch: '',
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (!this.privileges.canSeeContainersData) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      let data = new FormData();
      data.append('search', this.table.search);
      data.append('sort_by', this.table.sort_by);
      data.append('sort_dir', this.table.sort_dir);
      data.append('perPage', this.table.perPage);
      data.append('page', this.table.page);

      data.append('nameSearch', this.table.nameSearch);
      data.append('productSearch', this.table.productSearch);
      data.append('alcoholSearch', this.table.alcoholSearch);
      data.append('totalSearch', this.table.totalSearch);
      data.append('levelSearch', this.table.levelSearch);
      data.append('pureSearch', this.table.pureSearch);
      data.append('capacitySearch', this.table.capacitySearch);
      data.append('overviewSearch', this.table.overviewSearch);

      axios.post(ApiRoutes.containers.datatable, data, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }).then((response) => {
        this.containers = response.data.results.data;
        this.paging.from = response.data.results.from;
        this.paging.to = response.data.results.to;
        this.paging.current_page = response.data.results.current_page;
        this.paging.last_page = response.data.results.last_page;
        this.paging.per_page = response.data.results.per_page;
        this.paging.total = response.data.results.total;
        this.paging.totalRecords = response.data.totalRecords;
      });
    },
    getActionButtons(id, row) {
      let dropdown = `<a data-id="${id}" class="nav-link dropdown-toggle" id="actions-dropdown-${id}" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-cogs fa-fw"></i></a>`;

      dropdown += `<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="actions-dropdown-${id}">`;
      if (row.level < row.capacity) {
        dropdown += `<li onclick="prepareAdd(${id}, ${row.level}, ${row.capacity}, ${row.product_id}, '${row.product_name}')"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-droplet me-1 ms-1"></i> Γέμισμα</a></li>`;
        dropdown += `<li onclick="prepareBlend(${id}, '${row.name}', ${row.product_id}, ${row.level})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-shuffle me-1"></i> Σύμμιξη</a></li>`;
      }
      if (row.product_id && row.level > 0) {
        dropdown += `<li onclick="prepareBottle(${id}, ${row.level}, ${row.capacity})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-bottle-droplet me-1 ms-1"></i> Εμφιάλωση</a></li>`;
        dropdown += `<li onclick="prepareTransform(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-solid fa-flask me-1"></i> Επεξεργασία</a></li>`;
        if (row.available_for_send > 0) {
          dropdown += `<li onclick="prepareTransfer(${id}, ${row.product_id}, ${row.level}, ${row.capacity})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-arrow-turn-up"></i> Μεταφορά Προϊόντος</a></li>`;
        }
      }
      if (this.privileges.canEditContainers) {
        dropdown += `<li onclick="edit(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-edit"></i> Διόρθωση</a></li>`;
      }
      if (this.privileges.canDestroyContainers) {
        dropdown += `<li><hr class="dropdown-divider" /></li>`;
        dropdown += `<li onclick="destroy(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-trash"></i> Διαγραφή</a></li>`;
      }
      dropdown += `</ul>`;

      return dropdown;
    },
    getMobileActionButtons(id, row) {
      let dropdown = '';
      if (row.level < row.capacity) {
        dropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="prepareAdd(${id}, ${row.level}, ${row.capacity}, ${row.product_id}, '${row.product_name}')"><i class="fas fa-droplet me-1"></i>Γέμισμα</button>`;
        dropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="prepareBlend(${id}, '${row.name}', ${row.product_id}, ${row.level})"><i class="fas fa-shuffle me-1"></i>Σύμμιξη</button>`;
      }
      if (row.product_id && row.level > 0) {
        dropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="prepareBottle(${id}, ${row.level}, ${row.capacity})"><i class="fas fa-bottle-droplet me-1"></i>Εμφιάλωση</button>`;
        dropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="prepareTransform(${id})"><i class="fas fa-flask me-1"></i>Επεξεργασία</button>`;
        if (row.available_for_send > 0) {
          dropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="prepareTransfer(${id}, ${row.product_id}, ${row.level}, ${row.capacity})"><i class="fas fa-arrow-turn-up me-1"></i>Μεταφορά Προϊόντος</button>`;
        }
      }
      if (this.privileges.canEditContainers) {
        dropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="edit(${id})"><i class="fas fa-edit me-1"></i>Διόρθωση</button>`;
      }
      if (this.privileges.canDestroyContainers) {
        dropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="edit(${id})"><i class="fas fa-trash me-1"></i>Διαγραφή</button>`;
      }
      return dropdown;

    },
    clickMobileContainer(id, container) {
      Swal.fire({
        title: 'ΕΠΙΛΟΓΕΣ ΔΕΞΑΜΕΝΗΣ',
        html: this.getMobileActionButtons(id, container, true),
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
      });

    },
    prepareStore() {
      this.$refs.containersModal.prepareStore();
    },
    prepareBottle(id, current, max) {
      this.$refs.bottlingModal.prepareBottle(id, current, max);
    },
    prepareAdd(id, current, max, product_id, product_name) {
      this.$refs.addingModal.prepareAdd(id, current, max, product_id, product_name);
    },
    prepareTransfer(container_id, product_id, current, max) {
      this.$refs.transferringModal.prepareTransfer(container_id, product_id, current, max);
    },
    prepareBlend(container_id, product_id, current, max) {
      this.$refs.blendingModal.prepareBlend(container_id, product_id, current, max);
    },
    prepareTransform(container_id) {
      this.$refs.transformModal.prepareTransform(container_id);
    },
    setPerPage(value) {
      this.table.perPage = value;
      this.fetchData(); // Call method to fetch data with new perPage value
    },
    changePage(page) {
      this.table.page = page;
      this.fetchData();
    },
    sort(field, dir) {
      this.table.sort_by = field;
      this.table.sort_dir = dir;
      this.fetchData();
    },
    exportContainers() {
      axios.get(apiRoutes.containers.export.excel, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        responseType: 'blob'
      }).then((response) => {
        this.saveExcelFile(response.data);
      }).catch((error) => errorHandle(error))
    },
    saveExcelFile(data) {
      const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `ΔΕΞΑΜΕΝΕΣ-${this.getCurrentDate()}.xlsx`; // Change the filename if necessary
      link.click();
    },
    getCurrentDate() {
      // Get the current date
      let currentDate = new Date();

      // Format the date as YYYYMMDDHHmm
      return currentDate.getFullYear() +
          ('0' + (currentDate.getMonth() + 1)).slice(-2) +
          ('0' + currentDate.getDate()).slice(-2) +
          ('0' + currentDate.getHours()).slice(-2) +
          ('0' + currentDate.getMinutes()).slice(-2);
    },
  }
}
</script>

<style>
th {
  cursor: pointer;
}
</style>
