<template>
  <div class="modal fade" ref="productsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-wine-bottle me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="mb-3">
              <label for="product-name" class="form-label">Ονομασία</label>
              <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control" id="product-name" v-model="form.name">
            </div>

            <div class="mb-3" ref="productLiquids">
              <label for="product-liquids" class="form-label">Σύνθεση</label>
              <vue-select id="product-liquids"
                          :options="liquids"
                          :multiple="true"
                          :placeholder="'Επιλέξτε Συστατικά'"
                          v-model="form.liquids"
                          @option:selected="filterLiquids('')"
                          @option:deselected="filterLiquids('')"
                          @keyup="filterLiquids($event.target.value)"
              >
                <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                </template>
              </vue-select>
            </div>

            <div class="mb-3" ref="productProducts">
              <label for="product-liquids" class="form-label">Σύνθεση (από ήδη υπάρχοντα προϊόντα)</label>
              <vue-select id="product-products"
                          :options="products"
                          :multiple="true"
                          :placeholder="'Επιλέξτε Προϊόντα'"
                          v-model="form.products"
                          @option:selected="filterProducts('')"
                          @option:deselected="filterProducts('')"
                          @keyup="filterProducts($event.target.value)"
              >
                <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                </template>
              </vue-select>
            </div>

            <div ref="productWaste" class="mb-3 d-none">
              <label for="product-waste" class="form-label">Φύρα</label>
              <input @keydown.enter.prevent="handleEnterKey" type="number" step=".01" class="form-control" id="product-waste"
                     v-model="form.waste">
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";
import errorHandle from "@/assets/js/Library/ErrorHandle";

export default {
  name: 'ProductsModal',
  components: {VueSelect},
  data() {
    return {
      privileges: {
        canStoreProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_STORE_PRODUCTS.value) === Privileges.products.CAN_STORE_PRODUCTS.value,
        canEditProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_EDIT_PRODUCTS.value) === Privileges.products.CAN_EDIT_PRODUCTS.value,
        canUpdateProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_UPDATE_PRODUCTS.value) === Privileges.products.CAN_UPDATE_PRODUCTS.value,
        canDestroyProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_DESTROY_PRODUCTS.value) === Privileges.products.CAN_DESTROY_PRODUCTS.value,
        canFilterLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.liquids.CAN_FILTER_LIQUIDS.value) === Privileges.liquids.CAN_FILTER_LIQUIDS.value,
      },
      title: '',
      button: null,
      modalRef: null,
      privilege: null,
      form: {
        id: 0,
        name: '',
        liquids: [],
        products: [],
      },
      liquids: [],
      products: [],
    }
  },
  created() {
    window.store = this.store;
    window.edit = this.edit;
    window.update = this.update;
    window.destroy = this.destroy;
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.productsModal);

    this.filterLiquids('');
    this.filterProducts('');
  },
  methods: {
    filterLiquids(value) {
      if (!this.privileges.canFilterLiquids) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      axios.post(apiRoutes.liquids.filter, {
        'name': value,
        'used_liquids': this.form.liquids
      }, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.liquids = [];
        response.data.forEach((item) => {
          this.liquids.push({value: item.id, label: item.name})
        })
      }).catch((error) => errorHandle(error));
    },
    filterProducts(value) {
      if (!this.privileges.canFilterLiquids) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      axios.post(apiRoutes.products.filter, {
        'name': value,
        'used_products': this.form.products
      }, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.products = [];
        response.data.forEach((item) => {
          this.products.push({value: item.id, label: item.name})
        })
      }).catch((error) => errorHandle(error));
    },
    prepareStore() {
      this.title = 'Προσθήκη νέου προϊόντος';
      this.button = ModalButtons.create;
      this.$refs.productWaste.classList.add('d-none');
      this.$refs.productProducts.classList.remove('d-none');

      this.liquids = [];
      this.form.liquids = [];

      this.products = [];
      this.form.products = [];

      this.filterLiquids('');
      this.filterProducts('');

      this.clearForm();
      this.openModal();
    },
    store() {
      if (!this.privileges.canStoreProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      const productData = new FormData();
      productData.append('name', this.form.name);
      if (this.form.liquids.length > 0) {
        this.form.liquids.forEach((liquid, index) => {
          productData.append(`liquids[${index}][id]`, parseInt(liquid.value))
        })
      }

      if (this.form.products.length > 0) {
        this.form.products.forEach((product, index) => {
          productData.append(`products[${index}][id]`, parseInt(product.value))
        })
      }

      axios.post(apiRoutes.products.store, productData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
            timer: 600,
            showConfirmButton: false,
          });
        }
        this.modalRef.hide();
      }).catch((error) => ErrorHandle(error));

      this.$emit('data-modified')
    },
    edit(id) {
      if (!this.privileges.canEditProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      this.title = 'Διόρθωση Στοιχείων Προϊόντος'
      this.button = ModalButtons.update(id)

      this.$refs.productWaste.classList.remove('d-none');
      this.$refs.productProducts.classList.add('d-none');

      axios.post(apiRoutes.products.edit, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          this.clearForm();
          this.form.name = response.data.name;

          let liquidsIds = response.data.liquids.split(',');
          let liquidsNames = response.data.liquid_names.split(',');

          liquidsIds.forEach((item, index) => {
            this.form.liquids.push({value: item, label: liquidsNames[index]})
            this.liquids.push({value: item.id, label: item.name});
          })

          this.form.waste = response.data.waste;
          this.form.id = id;

          this.filterLiquids('');

          this.openModal();

        }
      }).catch((error) => ErrorHandle(error));
    },
    update() {
      if (!this.privileges.canUpdateProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      const productData = {
        id: this.form.id,
        name: this.form.name,
        waste: this.form.waste
      };

      if (this.form.liquids.length > 0) {
        productData.liquids = [];

        this.form.liquids.forEach((liquid) => {
          productData.liquids.push({'id': parseInt(liquid.value)})
        })
      }

      axios.patch(apiRoutes.products.update, productData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
            timer: 600,
            showConfirmButton: false,
          });
        }
        this.closeModal();
      }).catch((error) => ErrorHandle(error));

      this.$emit('data-modified')
    },
    destroy(id) {
      if (!this.privileges.canDestroyProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.fire({
        title: 'Προσοχή',
        icon: 'warning',
        text: 'Αυτή η ενέργεια θα διαγράψει μόνιμα το προϊόν! Θέλετε σίγουρα να προχωρήσετε;',
        confirmButtonText: 'Διαγραφή',
        confirmButtonColor: 'red',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση',
        allowEnterKey: true
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'DELETE',
            url: apiRoutes.products.destroy,
            data: {'id': id},
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
          }).then((response) => {
            Swal.fire({
              title: 'Επιτυχία',
              icon: 'success',
              text: response.data.message,
              allowEnterKey: true,
              timer: 600,
              showConfirmButton: false,
            })
            this.datatable.ajax.reload(null, false);
          }).catch(error => ErrorHandle(error))

          this.$emit('data-modified')

        }
      });
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.form.id = 0;
      this.form.name = '';
      this.form.liquids = [];
    }
  }
}
</script>

<style scoped>

</style>