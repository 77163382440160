<template>
  <div class="modal fade" ref="addingModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-droplet me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row" ref="inputQuantity">
              <div class="col">
                <div class="mb-3">
                  <label for="container-liquid" ref="quantityTitleRef">Ποσότητα</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="number" id="container-liquid" step=".01" class="form-control"
                         value="0" v-model="form.quantity">
                </div>
              </div>
            </div>

            <div class="row d-none" ref="inputProducts">
              <div class="col">
                <div class="mb-3">
                  <label for="container-liquid">Προϊόν</label>
                  <vue-select id="container-liquid"
                              :options="products"
                              v-model="form.product_id"
                              @option:selected="filterProducts('')"
                              @keyup="filterProducts($event.target.value)"
                  >
                    <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                    </template>
                  </vue-select>
                </div>
              </div>
            </div>

            <div class="row d-none" ref="inputAlcohol">
              <div class="col">
                <div class="mb-3">
                  <label for="container-liquid">Αλκοολικός Βαθμός</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="number" id="container-alcohol" step=".01" class="form-control"
                         value="0" v-model="form.alcohol">
                </div>
              </div>
            </div>

            <div class="row" ref="inputNotes">
              <div class="col">
                <div class="mb-3">
                  <label for="container-notes">Σημειώσεις</label>
                  <textarea @keydown.enter.prevent="handleEnterKey" class="form-control" id="container-notes"
                            v-model="form.notes"></textarea>
                </div>
              </div>
            </div>


          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";
import menuTitles from "@/assets/js/Library/MenuTitles";

export default {
  name: 'AddingModal',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {VueSelect},
  created() {
    window.add = this.add;
  },
  data() {
    return {
      title: '',
      button: null,
      privileges: {
        canAdd: (BigInt(localStorage.getItem('privilege')) & Privileges.actions.CAN_ADD_PRODUCT_TO_CONTAINER.value) === Privileges.actions.CAN_ADD_PRODUCT_TO_CONTAINER.value,
        canFilterProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_FILTER_PRODUCTS.value) === Privileges.products.CAN_FILTER_PRODUCTS.value,
      },
      products: [],
      modalRef: null,
      privilege: null,
      form: {
        id: 0,
        quantity: 0,
        product_id: 0,
        alcohol: 0,
        notes: ''
      }
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.addingModal);
  },
  methods: {
    prepareAdd(id, current, max, product_id, product_name) {
      if (!this.privileges.canAdd) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();
      this.clearForm();

      this.title = 'Γέμισμα Δεξαμενής';
      this.button = ModalButtons.add(id)

      this.$refs.quantityTitleRef.innerHTML = `Ποσότητα (${current} / ${max})`;

      if (product_id !== null) {
        this.form.product_id = {value: product_id, label: product_name};
        this.$refs.quantityTitleRef.innerHTML += ` (${product_name})`;

        this.modalRef.show();
        return;
      }

      this.$refs.inputProducts.classList.remove('d-none')
      this.filterProducts('');

      this.openModal();
    },
    add(id) {
      if (!this.privileges.canAdd) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      const data = new FormData();
      data.append('container_id', id);
      data.append('product_id', this.form.product_id?.value);
      data.append('quantity', this.form.quantity);
      data.append('alcohol', this.form.alcohol);
      data.append('notes', this.form.notes);

      axios.post(apiRoutes.actions.add, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          })
          this.closeModal();
          this.$emit('data-modified');
        }
      }).catch((error) => ErrorHandle(error));
    },
    filterProducts(value) {
      if (!this.privileges.canFilterProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      axios.post(apiRoutes.products.filter, {'name': value, 'used_products': []}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.products = [];
        response.data.forEach((item) => {
          this.products.push({value: item.id, label: item.name})
        })
      }).catch((error) => ErrorHandle(error));
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.$refs.inputProducts.classList.add('d-none')
      this.form.id = 0;
      this.form.quantity = 0;
      this.form.waste = 0;
      this.form.notes = '';
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    }
  }
}
</script>

<style scoped>

</style>