<template>
  <div class="modal fade" ref="liquidsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-wine-bottle me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="mb-3">
              <label for="liquid-name" class="form-label">Ονομασία</label>
              <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control" id="liquid-name"
                     v-model="form.name">
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";

export default {
  name: 'LiquidsModal',
  components: {VueSelect},
  data() {
    return {
      title: '',
      button: null,
      privileges: {
        canStoreLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.liquids.CAN_STORE_LIQUIDS.value) === Privileges.liquids.CAN_STORE_LIQUIDS.value,
        canEditLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.liquids.CAN_EDIT_LIQUIDS.value) === Privileges.liquids.CAN_EDIT_LIQUIDS.value,
        canUpdateLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.liquids.CAN_UPDATE_LIQUIDS.value) === Privileges.liquids.CAN_UPDATE_LIQUIDS.value,
        canDestroyLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.liquids.CAN_DESTROY_LIQUIDS.value) === Privileges.liquids.CAN_DESTROY_LIQUIDS.value,
      },
      modalRef: null,
      privilege: null,
      form: {
        id: 0,
        name: ''
      },
      showPassword: false
    }
  },
  created() {
    window.store = this.store;
    window.edit = this.edit;
    window.update = this.update;
    window.destroy = this.destroy;
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.liquidsModal);
  },
  methods: {
    prepareStore() {
      this.title = 'Προσθήκη νέου συστατικού';
      this.button = ModalButtons.create;

      this.clearForm();
      this.openModal();
    },
    store() {
      if (!this.privileges.canStoreLiquids) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      const containerData = new FormData();
      containerData.append('name', this.form.name);

      axios.post(apiRoutes.liquids.store, containerData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
            timer: 600,
            showConfirmButton: false,
          });
          this.closeModal();
        }
      }).catch((error) => ErrorHandle(error));

      this.$emit('data-modified')
    },
    edit(id) {
      if (!this.privileges.canEditLiquids) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      this.title = 'Διόρθωση στοιχείων συστατικού';
      this.button = ModalButtons.update(id);

      axios.post(apiRoutes.liquids.edit, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          this.clearForm();
          this.form = response.data;

          this.openModal();

        }
      }).catch((error) => ErrorHandle(error));
    },
    update() {
      if (!this.privileges.canUpdateLiquids) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      const containerData = {};
      containerData.id = this.form.id;
      containerData.name = this.form.name;

      axios.patch(apiRoutes.liquids.update, containerData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
            timer: 600,
            showConfirmButton: false,
          });
          this.closeModal();
          this.$emit('data-modified')
        }
      }).catch((error) => ErrorHandle(error));

    },
    destroy(id) {
      if (!this.privileges.canDestroyLiquids) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.fire({
        title: 'Προσοχή',
        icon: 'warning',
        text: 'Αυτή η ενέργεια θα διαγράψει μόνιμα το συστατικό! Θέλετε σίγουρα να προχωρήσετε;',
        confirmButtonText: 'Διαγραφή',
        confirmButtonColor: 'red',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση',
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'DELETE',
            url: apiRoutes.liquids.destroy,
            data: {'id': id},
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
          }).then((response) => {
            Swal.fire({
              title: 'Επιτυχία',
              icon: 'success',
              text: response.data.message,
              allowEnterKey: true,
              timer: 600,
              showConfirmButton: false,
            })
            this.datatable.ajax.reload(null, false);
          }).catch(error => ErrorHandle(error))

          this.$emit('data-modified')

        }
      });
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.form.id = 0;
      this.form.name = '';
    }
  }
}
</script>

<style scoped>

</style>